<script>
//import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  ordersMethods,
  ordersGetters,
  orderMethods
} from "@/state/helpers";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { format } from 'date-fns'
import * as XLSX from 'xlsx'
import MultiRangeSlider from "multi-range-slider-vue";
//import axios from 'axios';

/**
 * Orders Component
 */
export default {
  components: {
    // Layout,
    Layout: () => import('../../layouts/main'),
    PageHeader,
    DatePicker,
    MultiRangeSlider
  },
  data() {
    return {
      title: "Orders",
      time1: null,
      downloadLoader : 0,
      tabItem : localStorage.getItem('activeTab') ? localStorage.getItem('activeTab') : 1,
      items: [
        {
          text: "Orders",
          active: true
        }
      ],
      emailLoader : false,
      prevRoute:null,
      selected: '',
      orderStatus:'all',
      paymentStatus: '',
      filterFrom:'',
      filterTo:'',
      submitted: false,
      showmodal: false,
      actionsModal:false,
      actionType:null,
      actionData:null,
      barMinValue: 25,
      barMaxValue: 100,
      sBarMinValue:25,
      sBarMaxValue : 100, 
      hideModal() {
        this.submitted = false;
        this.showmodal = false;
        this.contacts = {};
      },
      showGPModal:false,
      gpData:{},
      gpInfo:{registeredGp:false,informGp:false},
      sendGpEmail:'to_gp',
      emailto: 'customer',
      email:'',
      orderId:0,
      options: [
        { item: 'first_name', name: 'Filter By First Name' },
        { item: 'last_name', name: 'Filter By Last Name' },
        { item: 'email', name: 'Filter By Email' },
        { item: 'order_id', name: 'Filter By Order ID' },
        { item: 'postcode', name: 'Filter By Postcode' },
        { item: 'bmi', name: 'Filter By BMI' },
      ],
      orderStausOptions:[
        { value: 'pending', text: 'Pending' },
        { value: 'processing', text: 'Processing' },
        { value: 'approved', text: 'Approved' },
        { value: 'canceled', text: 'Canceled' },
        { value: 'on hold', text: 'On Hold'},
        { value: 'cancelled', text: 'Cancelled'}
      ],
      age_verification_options: [
          { value: 'verified', text: 'Verified' },
          { value: 'unverified', text: 'Unverified' },
          { value: 'not_attempt', text: 'Not Attempt' },
          { value: 'manually_updated', text: 'Manually_updated' }
        ],
      orderOptionsStatus : '',
      age_checked : '',
      sendToOther:false,
      perPage: 10,
      page:1,
      pageOptions: [10, 20, 50, 100],
      filter: '',
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "post_title", label: "Order ID" },
        { key: "date",label: "Date"},
        { key: "first_name",label: "First Name" },
        { key: "last_name", label: "Last Name" },
        { key: "email", label: "Email" },
        { key: "product", label: "Product" },
        { key: "variations", label: "Variations" },
        { key: "age_checked", label: "Age Checked" },
        { key: "status", label: "Status" },
        { key: "order_total",label: "Total" },
        { key: "action" }
      ],
      filterEnable:true,
      form:{},
      noData:[{
        email : 'No Product Found.'
      }],
      productsFilterOptions:[{value:"all",text:"All"}],
      selected_product:'all',
      variation_loader : false,
      productVariationOptions:[
        {value:"all",text:"All"}
      ],
      allProducts:[],
      variation:'all',
      filterUsed : {}, 
    }
  },
  computed: {
    ...ordersGetters,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => { 
        if(from.name == 'OrderDetails'){
           vm.prevRoute = from.name;
        }
        if(to.hash){
          if(to.hash == '#allorders'){
             vm.tabItem = 0;
          }
          if(to.hash == '#pending'){
             vm.tabItem = 1;
          }
          if(to.hash == '#shipped'){
             vm.tabItem = 2;
          }
          if(to.hash == '#unpaid'){
              vm.tabItem = 3;
          }
        }
        // to.hash
    });
  },
  async created() {
    let response = await axios.get('/wp-json/wp/v2/products?_fields[]=acf&_fields[]=id&_fields[]=title');
    let json = await response.data;
    for(let item of json){
      this.productsFilterOptions.push({value:item.id,text:item.title.rendered});
    }
    this.allProducts = json;
  },
  mounted() {
    this.tabItem = 0; // Set tabItem to 1 when the component is mounted
  },
  methods: {
    ...ordersMethods,
    ...orderMethods,
    handlePanigination(page) {
        let options = {
           page,
           filter : this.filter, 
           per_page : this.perPage,
           searchBy : this.selected,
           orderStatus : this.orderStatus,
           paymentStatus : this.paymentStatus,
           filterFrom : this.filterFrom,
           filterTo : this.filterTo,
           product : this.selected_product,
           bmi_from : this.sBarMinValue,
           bmi_to : this.sBarMaxValue,
           variation : this.variation
        }
          this.setOrders(options);
    },
    refresh_page(){
      let newIndex = this.tabItem;

      localStorage.setItem('activeTab', newIndex);
      console.log(newIndex);
      if (newIndex == 1) {
        this.orderStatus = 'pending';
      } else if (newIndex == 2) {
        this.orderStatus = 'shipped';
      } else if (newIndex == 3) {
        this.paymentStatus = 'failed';
        this.orderStatus = '';
      } else if (newIndex == 4) {
        this.paymentStatus = 'unpaid';
        this.orderStatus = '';
      } else {
        this.orderStatus = 'all';
      }

      this.remove_hash_from_url();
      let options = {
        page: this.currentPage,
        per_page: this.perPage,
        filter: this.filter,
        searchBy: this.selected,
        orderStatus: this.orderStatus,
        paymentStatus: this.paymentStatus,
        filterFrom: this.filterFrom,
        filterTo: this.filterTo,
        product: this.selected_product,
        variation: this.variation,
        bmi_from: this.sBarMinValue,
        bmi_to: this.sBarMaxValue
      };
      this.setOrders(options);
    },
    handleUserPerPage() {
      let options = {
          filter : this.filter,
          per_page : this.perPage,
          searchBy : this.selected,
          orderStatus : this.orderStatus,
          paymentStatus : this.paymentStatus,
          filterFrom : this.filterFrom,
          filterTo : this.filterTo,
          product : this.selected_product,
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue,
          variation : this.variation
        }
        this.setOrders(options);
    },
    handleSearch() {
      let options =
        { per_page : this.perPage,
          filter : this.filter,
          searchBy : this.selected,
          orderStatus : this.orderStatus,
          paymentStatus : this.paymentStatus,
          filterFrom : this.filterFrom,
          filterTo : this.filterTo,
          product : this.selected_product,
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue,
          variation : this.variation
        }
      if (this.filter.length >= 2 && this.filter.length) {
        this.setOrders(options);
      } else {
        this.setOrders({ ...options,page:1, options: '', searchBy: ''});
      }
    },
    handleSubmit(){
      this.emailLoader = true;
      this.sendEmail({
        order_id:this.orderId,
        emailto:this.emailto,
        sendToOther:this.sendToOther,
        email:this.email
      }).then(response => {
        if(response.success){
           this.$swal({
              toast: true,
              title: response.message,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
              }
           }); 
           this.emailLoader = false;
        }else
        {
          Swal.fire(
          'Failed',
             response.message,
            'error'
          )
          this.emailLoader = false;
        }
      });
    },
    handleFilterSearch(){
      let options = {
          per_page : this.perPage,
          filter : this.filter,
          searchBy : this.selected,
          orderStatus : this.orderStatus,
          paymentStatus : this.paymentStatus,
          filterFrom : this.filterFrom,
          filterTo : this.filterTo,
          product : this.selected_product,
          variation : this.variation,
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue
        }
        this.setOrders(options);
    },
    resetSearchFilter(){
      this.filter = '';
      this.selected = '';
      this.selected_product = 'all';
      this.variation = 'all';
      let options = {
          page:1,
          per_page : this.perPage,
          orderStatus : this.orderStatus,
          paymentStatus : this.paymentStatus,
          filterFrom : this.filterFrom,
          filterTo : this.filterTo,
          filter: '', 
          searchBy: '',
          product : 'all',
          variation : 'all',
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue,
      }
      this.setOrders(options);
      this.filterEnable = true;
    },
    handleFilterValue(){
      if(this.filter.length >= 2){
        this.filterEnable = false;
      }else
      {
        this.filterEnable = true;
      }
    },
    handleDates(event){
       this.filterFrom = format(new Date(event[0]), 'yyyy-MM-dd');
       this.filterTo = format(new Date(event[1]), 'yyyy-MM-dd');
       let options = { 
           page:1,
           per_page : this.perPage,
           filter: '', 
           searchBy: '',
           orderStatus : this.orderStatus,
           paymentStatus : this.paymentStatus,
           filterFrom : this.filterFrom,
           filterTo : this.filterTo,
           product : this.selected_product,
           variation : this.variation,
           bmi_from : this.sBarMinValue,
           bmi_to : this.sBarMaxValue,
          }
      this.setOrders(options);
    },
    resetDatesFilter(){
      this.filterFrom = '';
      this.filterTo = '';
      let options = {
           page:1,
           per_page : this.perPage,
           filter: '', 
           searchBy: '',
           orderStatus : this.orderStatus,
           paymentStatus : this.paymentStatus,
           filterFrom : this.filterFrom,
           filterTo : this.filterTo,
           product : this.selected_product,
           variation : this.variation,
           bmi_from : this.sBarMinValue,
           bmi_to : this.sBarMaxValue,
      }
      this.setOrders(options);
    },
    enableFilterBtn(){
      this.filterEnable = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    changePrductVariations(){
      this.variation_loader = true;
      let product_id = this.selected_product;
      if(product_id == 'all'){
        this.productVariationOptions = [{value:"all",text:"All"}];
        this.variation_loader = false;
        return false;
      }
      let product = this.allProducts.find(item => item.id === product_id);
      let { variation }  = product.acf;
      let variationOptions = [{value:"all",text:"All"}];
      if(variation){
      for(let item of variation){
        for(let option of item.variation_option){
          console.log(option);
          variationOptions.push({value:option.name,text:option.name});
        }
      }
     }
     this.productVariationOptions = variationOptions;
     setTimeout(()=>{
       this.variation_loader = false;
     },1000)
    },
    editActions(type,data){
      this.actionType =  type;
      this.actionData =  data;
      if(type == 'age_checked'){
        this.age_checked = data.age_checked;
      }else
      {
        this.orderOptionsStatus = data.status;
      }
      this.actionsModal = true;
    },
    download : function() {
    let exportData = [];
    this.orders.forEach((item) => {
      let variations = '';
      if(item.product_detail.variation){
        item.product_detail.variation.forEach(innerItem => {
          variations += innerItem.variation_name+' , '+innerItem.name+ ' , '+innerItem.price+ (innerItem.quantity ? ' , ' +innerItem.quantity+' , ' : ' ,');
        })
      }
      let orderData = { 
         "Order No" : item.post_title,
         "Order Date" : item.date,
         "First Name" : item.first_name,
         "Last Name" : item.last_name,
         "Email" : item.email,
         "Product" : item.product,
         "Variation" : variations,
         "Status" : item.status,
         "Age Checked" : item.age_checked,
         "Payment Status" : item.payment_status,
         "Order Total" : item.order_total,
       }
       exportData.push(orderData);
      })
      var wscols = [{wch:15},{wch:20},{wch:20},{wch:20},{wch:35},{wch:35},{wch:45},{wch:20},{wch:20},{wch:20},{wch:20}];
      const data = XLSX.utils.json_to_sheet(exportData)
      data['!cols'] = wscols;
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb,'orders.xlsx');
    },
    async downloadAll(){
      if(this.allOrders.length > 0){
      let data = [];
      let response = '';
      let start = 1;
      let isFirstChunk = true;
      let continueLoop = true;
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      var wscols = [{wch:12},{wch:15},{wch:20},{wch:20},{wch:25},{wch:35},{wch:20},{wch:20},{wch:20},{wch:20},{wch:20}];
      while (continueLoop) {
            let options = { page: start, data: this.allOrders };
            response = await this.getAllCSVOrders(options);
            data = response.data;
            console.log(response);
            this.downloadLoader = response.percentage;

            if (data.length === 0) {
                continueLoop = false;
                break;
            }
            let sheetData = '';
          if (isFirstChunk) {
              sheetData = XLSX.utils.sheet_add_json(worksheet, data, { origin: -1 });
              isFirstChunk = false;
          } else {
              sheetData = XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 });
          }
            sheetData['!cols'] = wscols;
            console.log(`Downloaded ${data.length} records`);
            ++start;
        }
      const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'products.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      console.log('Download complete');
        }
    },
    update_sBarValues(e) {
      this.sBarMinValue = e.minValue;
      this.sBarMaxValue = e.maxValue;
    },
    remove_hash_from_url() {
      var uri = window.location.toString();
      if (uri.indexOf("#") > 0) {
          var clean_uri = uri.substring(0,
                          uri.indexOf("#"));
          window.history.replaceState({},
                  document.title, clean_uri);
      }
    },
    handleAgeVerification(postId){
      let data = {
        age_checked:this.age_checked,
        post_id : postId
      }
      this.editAgeChecked(data);
      this.actionsModal = false;
    },
    changeStatus(postId){
      let data = {
        post_id : postId,
        status:this.orderOptionsStatus
      }
      this.editStatus(data);
      this.actionsModal = false;
    },
    getGPDetails(orderId){
      this.orderId = orderId;
      this.getGPData(orderId).then(response=>{
        this.gpData = response;
        if(response && response.questions){
          try {
              if(response.questions[0]['answer'] == 'yes'){
              this.gpInfo.registeredGp = true;
            } 
            if(response.questions[1]['answer'] == 'yes'){
              this.gpInfo.informGp = true;
            } 
          } catch (error) {
            console.log(error);
          }  
       }
        this.showGPModal = true;
      });
    },
    sendEmailToGP(orderId){
       this.emailLoader = true;
       let options = {
         orderId:orderId,
         sendTo : this.sendGpEmail,
         email : this.gpEmail
       }
       this.sendEmailGP(options).then(response => {
          if(response.success){
            Swal.fire('Email Sent',response.message,'success');
            this.showGPModal = false;
            this.emailLoader = false;
          }else
          {
            Swal.fire('Email Sent Failed',response.message,'warning');
            this.emailLoader = false;
          }
       });
    }
  },
  watch: {
    tabItem(newIndex,previousIndex){
      if(this.prevRoute == 'OrderDetails' && newIndex == previousIndex){
        return false;
      }
      localStorage.setItem('activeTab',newIndex);
      console.log(newIndex);
      (newIndex == 1) ? this.orderStatus = 'pending' : (newIndex == 2) ? this.orderStatus = 'shipped' : (newIndex == 3) ? (this.paymentStatus = 'failed', this.orderStatus = '') : (newIndex == 4) ? (this.paymentStatus = 'unpaid', this.orderStatus = '') : this.orderStatus = 'all';

      if(this.$route.hash){
          if(this.$route.hash == '#allorders'){
             this.tabItem = 0;
          }
          if(this.$route.hash == '#pending'){
            this.tabItem = 1;
          }
          if(this.$route.hash == '#shipped'){
            this.tabItem = 2;
          }
          if(this.$route.hash == '#failed'){
            this.tabItem = 3;
          }
          if(this.$route.hash == '#unpaid'){
            this.tabItem = 4;
          }
        }

      this.remove_hash_from_url();
      let options = { 
          page : this.currentPage,
          per_page : this.perPage,
          filter : this.filter,
          searchBy : this.selected,
          orderStatus : this.orderStatus,
          paymentStatus : this.paymentStatus,
          filterFrom : this.filterFrom,
          filterTo : this.filterTo,
          product : this.selected_product,
          variation : this.variation,
          bmi_from : this.sBarMinValue,
          bmi_to : this.sBarMaxValue
        }
        this.setOrders(options);
    }
  },
};
</script>

<template>
  <Layout>
   
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <!-- {{  downloadLoader }} % -->
            <!-- @input="handleTabData($event)" -->            
                <div class="timeline-refresh mt-2">
                  <button class="btn mt-1 pt-1 pb-1 ps-10 pe-10 d-flex justify-content-center align-items-center btn-outline-primary collapsed " @click="refresh_page"><i class="mdi mdi-refresh font-size-15"></i>Reload</button>
                </div>
            <b-tabs nav-class="nav-tabs-custom" v-model="tabItem">
              
              <div class="row mt-4 mb-2 align-items-center justify-content-between">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center mb-0">
                        Show&nbsp;
                        <b-form-select v-model="perPage" @change="handleUserPerPage('all')" size="sm"
                          :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search  -->

                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter"
                      class="row no-gutters align-items-center dataTables_filter text-md-right">
                      <b-col>
                        <div class="d-flex align-items-center">
                         <date-picker v-model="time1" range @confirm="handleDates($event)" @clear="resetDatesFilter" :confirm="true" confirm-text="Filter" :show-second="false" placeholder="Filter products from specific dates"></date-picker>
                         <b-button variant="outline-primary" class="py-1 ml-3 w-25 d-flex justify-content-center align-items-center" v-b-toggle.sidebar-right><i class="mdi mdi-filter"></i> Filters</b-button>
                         <b-button variant="outline-primary" class="py-1 ml-3 w-25 d-flex justify-content-center align-items-center" v-on:click="download"><i class="mdi mdi-file-chart"></i> Export</b-button>
                         <!-- <b-button variant="outline-primary" class="py-1 ml-3 w-25 d-flex justify-content-center align-items-center" v-on:click="downloadAll"><i class="mdi mdi-file-chart"></i> Export All</b-button> -->
                      </div>
                      </b-col>
                    </div>
                  </div>
                    <!-- End search  -->
              </div>
          
              <b-tab title-link-class="p-3" :active="($route.hash == '#allorders' || tabItem == 0) ? true : false">
                <template v-slot:title>
                  <a class="font-weight-bold">All Orders</a>
                </template>
                <div class="table-responsive">
                  
                  <!-- :filter="filter" :filter-included-fields="filterOn" -->
                  <b-table class="table-centered" v-if="orders" striped :busy="loader" :items="orders" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template> 
                    <template v-slot:cell(post_title)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(variations)="row">
                      <template v-if="row.item.product_detail.variation">
                      <span v-for="(variation,index) in row.item.product_detail.variation" :key="index">
                        {{  variation.variation_name +': '+ variation.name }}
                        <span v-if="(variation.variation_name != 'Sharps Bin') && (variation.variation_name != 'Needles')">,Quantity: {{ variation.qty ? variation.qty : 1 }}</span>
                        <span v-if="index != Object.keys(row.item.product_detail.variation).length - 1">, </span>
                      </span>
                      </template>
                    </template>

                    <template v-slot:cell(status)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'canceled',
                        'badge-success': `${row.value}` === 'shipped',
                        'badge-warning': `${row.value}` === 'pending',
                        'badge-soft-info': `${row.value}` === 'processing'
                      }">
                      </span> 
                      <span :class="{
                        'text-danger': `${row.value}` === 'canceled',
                        'text-success': `${row.value}` === 'shipped',
                        'text-warning': `${row.value}` === 'pending',
                        'text-info': `${row.value}` === 'processing'
                       }">{{ capitalizeFirstLetter(row.value === 'shipped' ? 'Approved' : row.value) }}</span>
                       <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('status',row.item)"></i>
                    </template>

                    <template v-slot:cell(age_checked)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'unverified',
                        'badge-success': `${row.value}` === 'verified',
                        'badge-warning': `${row.value}` === 'not_attempt'
                      }">
                     </span>
                     <span :class="{
                        'text-danger': `${row.value}` === 'unverified',
                        'text-success': `${row.value}` === 'verified',
                        'text-warning': `${row.value}` === 'not_attempt'
                      }"> {{ capitalizeFirstLetter(row.value) }} </span>
                      <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('age_checked',row.item)"></i>
                    </template>
                    <template v-slot:cell(order_total)="row">
                      £{{row.value}}
                    </template>
                    
                    <template v-slot:cell(action)="row">
                      <router-link :to="'/order/' +row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                      <a href="javascript:;" class="mr-3 text-success d-none" @click="getGPDetails(row.item.ID)" v-b-tooltip.hover
                        title="Patient GP info">
                        <i class="mdi mdi-information-outline font-size-18"></i>
                      </a>
                      <a href="javascript:;" class="mr-3 text-success" @click="showmodal = true; email=row.item.email; orderId = row.item.ID;" v-b-tooltip.hover
                        title="Send Email">
                        <i class="mdi mdi-email-send font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-table  v-else :items="noData" :fields="fields"> 
                    <template v-slot:cell(email)>
                     <h5 class="mt-4 mb-0">No Products Found.</h5>
                   </template>
                  </b-table>
                  
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPage" :total-rows="totalOrdes" :per-page="perPage"
                          @change="handlePanigination($event,'all')">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <!-- Pending Orders tab -->
              <b-tab title-link-class="p-3" :active="($route.hash == '#pending' || tabItem == 1) ? true : false">
                <template v-slot:title>
                  <a class="font-weight-bold pill">Incomplete</a>
                </template>

                <div class="table-responsive">
                  <!-- :filter="filter" :filter-included-fields="filterOn" -->
                  <b-table v-if="orders" class="table-centered" :busy="loader" :items="orders" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(post_title)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    
                    <template v-slot:cell(variations)="row">
                      <template v-if="row.item.product_detail.variation">
                      <span v-for="(variation,index) in row.item.product_detail.variation" :key="index">
                        {{  variation.variation_name +': '+ variation.name }}
                        <span v-if="(variation.variation_name != 'Sharps Bin' && variation.variation_name != 'Needles')">,Quantity: {{ variation.qty ? variation.qty : 1 }}</span>
                        <span v-if="index != Object.keys(row.item.product_detail.variation).length - 1">, </span>
                      </span>
                      </template>
                    </template>

                    <template v-slot:cell(status)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'canceled',
                        'badge-success': `${row.value}` === 'shipped',
                        'badge-warning': `${row.value}` === 'pending',
                        'badge-soft-info': `${row.value}` === 'processing'
                      }">
                      </span> 
                      <span :class="{
                        'text-danger': `${row.value}` === 'canceled',
                        'text-success': `${row.value}` === 'shipped',
                        'text-warning': `${row.value}` === 'pending',
                        'text-info': `${row.value}` === 'processing'
                       }">{{ capitalizeFirstLetter(row.value === 'shipped' ? 'Approved' : row.value) }}</span>
                       <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('status',row.item)"></i>
                    </template>

                    <template v-slot:cell(age_checked)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'unverified',
                        'badge-success': `${row.value}` === 'verified',
                        'badge-warning': `${row.value}` === 'not_attempt'
                      }">
                     </span>
                     <span :class="{
                        'text-danger': `${row.value}` === 'unverified',
                        'text-success': `${row.value}` === 'verified',
                        'text-warning': `${row.value}` === 'not_attempt'
                      }"> {{ capitalizeFirstLetter(row.value) }} </span>
                      <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('age_checked',row.item)"></i>
                    </template>

                    <template v-slot:cell(order_total)="row">
                      £{{row.value}}
                    </template>

                    <template v-slot:cell(action)="row">
                      <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                      <a href="javascript:;" class="mr-3 text-success d-none" @click="getGPDetails(row.item.ID)" v-b-tooltip.hover
                        title="Patient GP info">
                        <i class="mdi mdi-information-outline font-size-18"></i>
                      </a>
                      <a href="javascript:;" class="mr-3 text-success" @click="showmodal = true; email=row.item.email; orderId = row.item.ID;" v-b-tooltip.hover
                        title="Send Email">
                        <i class="mdi mdi-email-send font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-table  v-else :items="noData" :fields="fields"> 
                    <template v-slot:cell(email)>
                     <h5 class="mt-4 mb-0">No Products Found.</h5>
                   </template>
                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPage" :total-rows="totalOrdes" :per-page="perPage"
                          @change="handlePanigination($event,'pending')">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

              </b-tab>


              <!-- Shipped Orders Tab -->
              <b-tab title-link-class="p-3" :active="($route.hash == '#shipped' || tabItem == 2) ? true : false">
                <template v-slot:title>
                  <a class="font-weight-bold">Approved</a>
                </template>

                <div class="table-responsive">
                  <!-- :filter="filter" :filter-included-fields="filterOn" -->
                  <b-table v-if="orders" class="table-centered" :busy="loader" :items="orders" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(post_title)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(variations)="row">
                      <template v-if="row.item.product_detail.variation">
                      <span v-for="(variation,index) in row.item.product_detail.variation" :key="index">
                        {{  variation.variation_name +': '+ variation.name }}
                        <span v-if="(variation.variation_name != 'Sharps Bin' && variation.variation_name != 'Needles')">,Quantity: {{ variation.qty ? variation.qty : 1 }}</span>
                        <span v-if="index != Object.keys(row.item.product_detail.variation).length - 1">, </span>
                      </span>
                      </template>
                    </template>

                    <template v-slot:cell(status)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'canceled',
                        'badge-success': `${row.value}` === 'shipped',
                        'badge-warning': `${row.value}` === 'pending',
                        'badge-soft-info': `${row.value}` === 'processing'
                      }">
                      </span> 
                      <span :class="{
                        'text-danger': `${row.value}` === 'canceled',
                        'text-success': `${row.value}` === 'shipped',
                        'text-warning': `${row.value}` === 'pending',
                        'text-info': `${row.value}` === 'processing'
                       }">{{ capitalizeFirstLetter(row.value === 'shipped' ? 'Approved' : row.value) }}</span>
                       <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('status',row.item)"></i>
                    </template>

                    <template v-slot:cell(age_checked)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'unverified',
                        'badge-success': `${row.value}` === 'verified',
                        'badge-warning': `${row.value}` === 'not_attempt'
                      }">
                     </span>
                     <span :class="{
                        'text-danger': `${row.value}` === 'unverified',
                        'text-success': `${row.value}` === 'verified',
                        'text-warning': `${row.value}` === 'not_attempt'
                      }"> {{ capitalizeFirstLetter(row.value) }} </span>
                      <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('age_checked',row.item)"></i>
                    </template>

                    <template v-slot:cell(action)="row">
                      <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                      <a href="javascript:;" class="mr-3 text-success d-none" @click="getGPDetails(row.item.ID)" v-b-tooltip.hover
                        title="Patient GP info">
                        <i class="mdi mdi-information-outline font-size-18"></i>
                      </a>
                      <a href="javascript:;" class="mr-3 text-success" @click="showmodal = true; email=row.item.email; orderId = row.item.ID;" v-b-tooltip.hover
                        title="Send Email">
                        <i class="mdi mdi-email-send font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-table  v-else :items="noData" :fields="fields"> 
                    <template v-slot:cell(email)>
                     <h5 class="mt-4 mb-0">No Products Found.</h5>
                   </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPage" :total-rows="totalOrdes" :per-page="perPage"
                          @change="handlePanigination($event,'shipped')">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <!-- Unpaid Orders Tab -->
              <b-tab title-link-class="p-3" :active="($route.hash == '#failed' || tabItem == 3) ? true : false">
                <template v-slot:title>
                  <a class="font-weight-bold">Payment failed</a>
                </template>

                <div class="table-responsive">
                  <!-- :filter="filter" :filter-included-fields="filterOn" -->
                  <b-table v-if="orders" class="table-centered" :busy="loader" :items="orders" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(post_title)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(variations)="row">
                      <template v-if="row.item.product_detail.variation">
                      <span v-for="(variation,index) in row.item.product_detail.variation" :key="index">
                        {{  variation.variation_name +': '+ variation.name }}
                        <span v-if="(variation.variation_name != 'Sharps Bin' && variation.variation_name != 'Needles')">,Quantity: {{ variation.qty ? variation.qty : 1 }}</span>
                        <span v-if="index != Object.keys(row.item.product_detail.variation).length - 1">, </span>
                      </span>
                      </template>
                    </template>
                    
                    <template v-slot:cell(status)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'canceled',
                        'badge-success': `${row.value}` === 'shipped',
                        'badge-warning': `${row.value}` === 'pending',
                        'badge-soft-info': `${row.value}` === 'processing'
                      }">
                      </span> 
                      <span :class="{
                        'text-danger': `${row.value}` === 'canceled',
                        'text-success': `${row.value}` === 'shipped',
                        'text-warning': `${row.value}` === 'pending',
                        'text-info': `${row.value}` === 'processing'
                       }">{{ capitalizeFirstLetter(row.value === 'shipped' ? 'Approved' : row.value) }}</span>
                       <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('status',row.item)"></i>
                    </template>

                    <template v-slot:cell(age_checked)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'unverified',
                        'badge-success': `${row.value}` === 'verified',
                        'badge-warning': `${row.value}` === 'not_attempt'
                      }">
                     </span>
                     <span :class="{
                        'text-danger': `${row.value}` === 'unverified',
                        'text-success': `${row.value}` === 'verified',
                        'text-warning': `${row.value}` === 'not_attempt'
                      }"> {{ capitalizeFirstLetter(row.value) }} </span>
                      <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('age_checked',row.item)"></i>
                    </template>
                    
                    <template v-slot:cell(action)="row">
                      <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                      <a href="javascript:;" class="mr-3 text-success d-none" @click="getGPDetails(row.item.ID)" v-b-tooltip.hover
                        title="Patient GP info">
                        <i class="mdi mdi-information-outline font-size-18"></i>
                      </a>
                      <a href="javascript:;" class="mr-3 text-success" @click="showmodal = true; email=row.item.email; orderId = row.item.ID;" v-b-tooltip.hover
                        title="Send Email">
                        <i class="mdi mdi-email-send font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-table  v-else :items="noData" :fields="fields"> 
                    <template v-slot:cell(email)>
                     <h5 class="mt-4 mb-0">No Products Found.</h5>
                   </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPage" :total-rows="totalOrdes" :per-page="perPage"
                          @change="handlePanigination($event,'shipped')">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

               <!-- Unpaid Orders Tab -->
               <b-tab title-link-class="p-3" :active="($route.hash == '#unpaid' || tabItem == 4) ? true : false">
                <template v-slot:title>
                  <a class="font-weight-bold">Payment not attempted</a>
                </template>

                <div class="table-responsive">
                  <!-- :filter="filter" :filter-included-fields="filterOn" -->
                  <b-table v-if="orders" class="table-centered" :busy="loader" :items="orders" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(post_title)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(variations)="row">
                      <template v-if="row.item.product_detail.variation">
                      <span v-for="(variation,index) in row.item.product_detail.variation" :key="index">
                        {{  variation.variation_name +': '+ variation.name }}
                        <span v-if="(variation.variation_name != 'Sharps Bin' && variation.variation_name != 'Needles')">,Quantity: {{ variation.qty ? variation.qty : 1 }}</span>
                        <span v-if="index != Object.keys(row.item.product_detail.variation).length - 1">, </span>
                      </span>
                      </template>
                    </template>

                    <template v-slot:cell(status)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'canceled',
                        'badge-success': `${row.value}` === 'shipped',
                        'badge-warning': `${row.value}` === 'pending',
                        'badge-soft-info': `${row.value}` === 'processing'
                      }">
                      </span> 
                      <span :class="{
                        'text-danger': `${row.value}` === 'canceled',
                        'text-success': `${row.value}` === 'shipped',
                        'text-warning': `${row.value}` === 'pending',
                        'text-info': `${row.value}` === 'processing'
                       }">{{ capitalizeFirstLetter(row.value) }}</span>
                       <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('status',row.item)"></i>
                    </template>

                    <template v-slot:cell(age_checked)="row">
                      <span class="dot-badge d-inline-block mr-1" :class="{
                        'badge-danger': `${row.value}` === 'unverified',
                        'badge-success': `${row.value}` === 'verified',
                        'badge-warning': `${row.value}` === 'not_attempt'
                      }">
                     </span>
                     <span :class="{
                        'text-danger': `${row.value}` === 'unverified',
                        'text-success': `${row.value}` === 'verified',
                        'text-warning': `${row.value}` === 'not_attempt'
                      }"> {{ capitalizeFirstLetter(row.value) }} </span>
                      <i class="mdi mdi-lead-pencil font-size-18 ml-2" @click="editActions('age_checked',row.item)"></i>
                    </template>
                    
                    <template v-slot:cell(action)="row">
                      <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                      <a href="javascript:;" class="mr-3 text-success d-none" @click="getGPDetails(row.item.ID)" v-b-tooltip.hover
                        title="Patient GP info">
                        <i class="mdi mdi-information-outline font-size-18"></i>
                      </a>
                      <a href="javascript:;" class="mr-3 text-success" @click="showmodal = true; email=row.item.email; orderId = row.item.ID;" v-b-tooltip.hover
                        title="Send Email">
                        <i class="mdi mdi-email-send font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-table  v-else :items="noData" :fields="fields"> 
                    <template v-slot:cell(email)>
                     <h5 class="mt-4 mb-0">No Products Found.</h5>
                   </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPage" :total-rows="totalOrdes" :per-page="perPage"
                          @change="handlePanigination($event,'shipped')">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

            </b-tabs>
            
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal id="modal-1" centered v-model="showmodal" title="Send Order Email" title-class="text-dark font-18" hide-footer>
      <form @submit.prevent="handleSubmit()">
        <b-form-group label="Send Email To" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="emailto" :aria-describedby="ariaDescribedby" name="some-radios" class="mb-3"
            value="customer">Email to Customer</b-form-radio>
          <b-form-radio v-model="emailto" :aria-describedby="ariaDescribedby" name="some-radios" class="mb-3" value="admin">Email to
            Admin</b-form-radio>
          <b-form-checkbox id="checkbox-1" v-model="sendToOther" name="checkbox-1" value="yes"
            unchecked-value="no">
            Send Email to Other
          </b-form-checkbox>
        </b-form-group>
        <div class="form-group" v-if="sendToOther == 'yes'">
          <label for="exampleInputEmail1">{{ (emailto == 'customer') ? 'Send Customer Email To' : 'Send Admin Email To' }}</label>
          <input id="email" v-model="email" type="email" name="username" class="form-control"
            placeholder="Enter email" />
        </div>
        <div class="text-right">
          <button type="submit" :disabled="emailLoader" class="btn btn-success">
             <span v-if="emailLoader">Sending...</span>
             <span v-else>Send</span>
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->

    <!-- Order Actions Modal -->
    <b-modal id="modal-1" centered v-model="actionsModal" :title="(actionType == 'status') ? 'Change Order Status' : 'Change Age Checked Status'" title-class="text-dark font-18" hide-footer>
        <div class="order_status_wrap" v-if="actionType == 'status'">
          <h5 class="foont-size-15">Order status</h5>
          <b-form @submit.prevent="changeStatus(actionData.ID)" class="text-muted">
          <b-form-select v-model="orderOptionsStatus" :options="orderStausOptions"></b-form-select>
          <div class="mt-3">
            <button type="submit" :disabled="emailLoader" class="btn btn-primary px-4">
              <span v-if="emailLoader">Sending...</span>
              <span v-else>Save</span>
            </button>
          </div>
          </b-form>
        </div>

        <div class="order_status_wrap" v-if="actionType == 'age_checked'">
          <h5 class="foont-size-15">Age Verfication Status</h5>
          <b-form @submit.prevent="handleAgeVerification(actionData.ID)">
          <b-form-group>
              <b-form-select v-model="age_checked" :options="age_verification_options"></b-form-select>
          </b-form-group> 
          <b-button class="mt-2 px-4 " type="submit" variant="primary">Save</b-button>
          </b-form>
        </div>

    </b-modal>
    <!-- end modal -->

    <!-- Patient GP Modal -->
    <b-modal id="modal-1" centered v-model="showGPModal" title="GP Details" title-class="text-dark font-18" hide-footer>
        <div class="order_gp_wrap" v-if="gpData">
          <div class="order_gp_wrap_inner" v-if="gpInfo.registeredGp == true && gpInfo.informGp == true">
          <h5 class="foont-size-15 mb-3">Send Email to GP</h5>
          <div class="gpData">
          <b-form @submit.prevent="sendEmailToGP(orderId)" class="text-muted">
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio class="mb-1" v-model="sendGpEmail" :aria-describedby="ariaDescribedby" name="gpemail" value="to_gp">Send Email to GP</b-form-radio>
              <b-form-radio v-model="sendGpEmail" :aria-describedby="ariaDescribedby" name="gpemail" value="to_ohther">Send Email to other</b-form-radio>
            </b-form-group>
            <div class="mt-3 mb-3">
              <div class="mb-3" v-if="sendGpEmail == 'to_ohther'">
              <b-form-input
                id="gp-email"
                type="email"
                v-model="gpEmail"
                placeholder="Enter GP email"
                required
              ></b-form-input>
             </div>
            <button type="submit" :disabled="emailLoader" class="btn btn-primary px-4">
              <span v-if="emailLoader">Sending...</span>
              <span v-else>Send</span>
            </button>
          </div>
          </b-form>
          </div>
        </div>
        <div class="" v-else>
          <div class="" v-if="gpData.questions">
            <div class="not_regestered_gp" v-for="(question,index) in gpData.questions" :key="index">
              <strong>{{question.question}}</strong>
              <p>Answer: {{question.answer}}</p> 
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <i class="mdi mdi-alert text-center text-warning d-table mx-auto" style="font-size: 42px;"></i>
        <h5 class="foont-size-15 text-center">Patient do not have any GP Data.</h5>
      </div>

    </b-modal>


    <b-sidebar id="sidebar-right" right shadow bg-variant="light" backdrop backdrop-variant="transparent">
      <b-form class="px-3 py-4" @submit.prevent="handleFilterSearch()" @reset="resetSearchFilter">
        <h5>Filtering & Sorting</h5>
        <hr class="mt-3 mb-4"/>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="order_id">Order ID</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected"  class="card-title" value="email">Email</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="first_name">First Name</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="last_name">Last Name</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="phone_number">Phone Number</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="postcode">Post Code</b-form-radio>
        </b-form-group>

        <b-form-group id="input-group-2" class="card-title mb-3" 
          :label="selected == 'first_name' ? 'Enter First Name:' :  
          selected == 'order_id' ? 'Enter Order ID:' : 
          selected == 'email' ?  'Enter Email:' :  
          selected == 'last_name' ?  'Enter Last Name:' :
          selected == 'postcode' ?  'Enter Post Code:' :
          'Enter Phone Number:'" 
          label-for="input-2">
          <b-form-input
            id="input-2"
            :type="selected == 'email' ? 'email' : 'search'"
            v-model="filter"
            @input="handleFilterValue"
            :required="selected ? true : false"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" class="card-title mb-3 range-slider"  label="Select BMI range">
          <MultiRangeSlider
            :min="25"
            :max="100"
            :min-value="sBarMinValue"
            :max-value="sBarMaxValue"
            :step="0.25"
            :ruler="false"
            :range-margin="1"
            @input="update_sBarValues"
          />
       </b-form-group>
        <b-form-group label="Select Product">
          <b-form-select  v-model="selected_product" @change="enableFilterBtn(); changePrductVariations($event)" :options="productsFilterOptions"></b-form-select>
        </b-form-group>
        <b-form-group >
          <div class="d-flex align-items-center">
           <label>Select Variation:</label>
           <b-spinner variant="success" v-if="variation_loader" class="d-inline-block ml-2 mb-2" small label="Small Spinner" type="grow"></b-spinner>
          </div>
          <b-form-select v-model="variation" @change="enableFilterBtn()" :options="productVariationOptions"></b-form-select>
        </b-form-group>
        <b-button variant="success" class="mr-2" type="submit">
            <i class="ri-check-line align-middle mr-2"></i> Submit
        </b-button>
        <b-button variant="danger" type="reset">
            <i class="ri-close-line align-middle mr-2"></i> Reset
        </b-button>
      </b-form>
    </b-sidebar>

  </Layout>
</template>

<style scoped>
.select_search-type {
  height: 30px;
}
.dot-badge{
  width: 6px;
  height: 6px;
  border-radius: 100px;
}
#sidebar-right {
    background: #fff !important;
}
.range-slider >>> .multi-range-slider{
    border: unset;
    box-shadow: unset;
    padding: 15px 2px;
  }
  .range-slider >>> .multi-range-slider .bar-inner {
    border: unset;
    box-shadow: unset;
    background-color: #1cbb8c;
}
.range-slider >>> .multi-range-slider .thumb::before {
    background-color: white;
    border: solid 2px #ff3d60;
    box-shadow: unset;
}
.range-slider >>> .multi-range-slider .thumb .caption * {
    background-color: white;
    color: black;
    box-shadow: unset;
}
.dataTables_paginate ul {
  display: flex;
}
.table-centered.table-responsive-sm i.mdi.mdi-lead-pencil:hover {
    color: #898989;
    cursor: pointer;
}
.timeline-refresh{
  display: block;
  float:right;
}
</style>  